@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
    height: 100%;
    background-color: #010026;
    @apply text-white;
}

.map-location {
    display: flex;
    justify-content: center !important;
}

.footer-title {
    color: lightskyblue !important;
}

.testimonial-button {
    box-shadow:inset 0px 1px 0px 0px #fce2c1;
    background: #ffc477 linear-gradient(to bottom, #ffc477 5%, #fb9e25 100%);
    border-radius:6px;
    border:1px solid #eeb44f;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:15px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0px 1px 0px #cc9f52;
}
.testimonial-button:hover {
    background: #fb9e25 linear-gradient(to bottom, #fb9e25 5%, #ffc477 100%);
}
.testimonial-button:active {
    position: relative;
    top: 1px;
}